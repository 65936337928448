import { Button } from "@mui/material";
import React from "react";

interface CampaignProps {
  campaign_id: string;
  campaign_title: string;
  image: string;
  start_date: string;
  end_date: string;
  colors: {
    primary: string;
    secondary: string;
  };
  social_share_templates: any;
  onSelectCampaign: (campaign_id: string) => void;
}

const CampaignContainer: React.FC<CampaignProps> = ({
  campaign_id,
  campaign_title,
  image,
  start_date,
  end_date,
  colors,
  social_share_templates,
  onSelectCampaign,
}) => {
  const handleSelectCampaign = () => {
    onSelectCampaign(campaign_id);
  };

  return (
    <div
      style={{
        border: "2px solid lightgrey",
        borderRadius: "8px",
        padding: "16px",
        maxWidth: "400px",
        color: colors.primary,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      ></div>
      <div></div>
      <h4 style={{ textAlign: "center", marginBottom: "15px" }}>
        {campaign_title}
      </h4>
      <img
        src={social_share_templates[0].image}
        alt={campaign_title}
        style={{
          width: "100%",
          height: "auto",
          borderRadius: "4px",
          marginBottom: "16px",
          boxShadow: "rgb(0 0 0 / 20%) 0px 0px 10px 0px",
        }}
      />
      <div>
        <p>
          Valid Through: {new Date(start_date).toLocaleDateString()} -{" "}
          {new Date(end_date).toLocaleDateString()}
        </p>
      </div>

      <div>
        <ul>
          {social_share_templates.map((template: any, index: number) => (
            <li key={index}>
              {typeof template === "string" ? template : template.text}
            </li>
          ))}
        </ul>
      </div>

      <Button
        variant="contained"
        onClick={handleSelectCampaign}
        style={{
          marginTop: "16px",
          backgroundColor: colors.primary,
          color: "var(--dark-green)",
        }}
      >
        Select
      </Button>
    </div>
  );
};

export default CampaignContainer;
