import "./campaign.css";

import {Button, Snackbar} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React, { useEffect, useRef, useState } from "react";

interface EmailCardProps {
  template: string;
}

const sanitizeTemplate = (template: string): string => {
  return template
    .replace(/<\/*(html|head|body)[^>]*>/gi, "") // Remove html, head, body tags
    .trim();
};

const EmailCard: React.FC<EmailCardProps> = ({ template }) => {
  const sanitizedTemplate = sanitizeTemplate(template);
  const contentRef = useRef<HTMLDivElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState("auto");
  const [serviceProvider, setServiceProvider] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleServiceProviderClick = (provider: any) => {
    setServiceProvider(provider);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.transform = "none";
      const { height, width } = contentRef.current.getBoundingClientRect();

      setWrapperHeight(`${height * 0.45 + 115}px `);

      contentRef.current.style.transform = "scaleX(.45) scaleY(0.435)";
      contentRef.current.style.transformOrigin = "top";
    }
  }, [sanitizedTemplate]);

  const handleCopy = async () => {
    const blob = new Blob([template], { type: "text/html" });
    const data = [new ClipboardItem({ "text/html": blob })];
    navigator.clipboard
      .write(data)
      .then(() =>
        alert(
          "HTML email design copied to clipboard! Ready to paste into Gmail."
        )
      )
      .catch((err) => console.error("Failed to copy HTML: ", err));
  };

  const copyToClipboard = () => {
    if (!serviceProvider) {
      setSnackbarOpen(true);
      return;
    }
    navigator.clipboard.writeText(template).then(
      () => alert("Template copied to clipboard!"),
      () => alert("Failed to copy text.")
    );
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="email-card-wrapper" style={{ height: wrapperHeight }}>
      <div
        ref={contentRef}
        className="email-card-content"
        dangerouslySetInnerHTML={{ __html: sanitizedTemplate }}
      />
      <div
        style={{
          width: "100%",
          padding: "0px 10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="campaign-email-builder-button-container">
          <div
            className="campaign-email-builder-button"
            style={{
              backgroundColor:
                serviceProvider === "MailChimp" ? "#BBBBBB" : "#e6e6e6",
            }}
            onClick={() => handleServiceProviderClick("MailChimp")}
          >
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/MailChimp.svg/800px-MailChimp.svg.png" />
          </div>
          <div
            className="campaign-email-builder-button"
            style={{
              backgroundColor:
                serviceProvider === "ConstantContact" ? "#BBBBBB" : "#e6e6e6",
            }}
            onClick={() => handleServiceProviderClick("ConstantContact")}
          >
            <img src="https://www.constantcontact.com/blog/wp-content/uploads/2022/05/CTCT_Logo_H_Stack_FC_RGB.png" />
          </div>
          <div
            className="campaign-email-builder-button"
            style={{
              backgroundColor:
                serviceProvider === "SendGrid" ? "#BBBBBB" : "#e6e6e6",
            }}
            onClick={() => handleServiceProviderClick("SendGrid")}
          >
            <img src="https://cdn.worldvectorlogo.com/logos/sendgrid-2.svg" />
          </div>
          <div
            className="campaign-email-builder-button"
            style={{
              backgroundColor:
                serviceProvider === "Gmail" ? "#BBBBBB" : "#e6e6e6",
            }}
            onClick={() => handleServiceProviderClick("Gmail")}
          >
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/512px-Gmail_icon_%282020%29.svg.png" />
          </div>
        </div>
        <Button
          variant="contained"
          onClick={serviceProvider === "Gmail" ? handleCopy : copyToClipboard}
          style={{
            backgroundColor: serviceProvider
              ? "var(--color-primary)"
              : "#e0e0e0",
            color: serviceProvider ? "var(--dark-green)" : "#9e9e9e",
          }}
        >
          COPY TEMPLATE
        </Button>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          Please select a service provider before copying the template.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default EmailCard;
