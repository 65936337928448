import "./campaign.css";

import { Box, Button } from "@mui/material";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";

import React from "react";

interface SocialShareTemplateProps {
  template: {
    text: string;
    image: string;
    share_url: string;
  };
}

const SocialShareTemplate: React.FC<SocialShareTemplateProps> = ({
  template,
}) => {
  const handleCopy = () => {
    const contentToCopy = `${template.text}\n${template.share_url}`;
    navigator.clipboard.writeText(contentToCopy).then(
      () => alert("Content copied to clipboard!"),
      (err) => console.error("Failed to copy content: ", err)
    );
  };

  return (
    <div className="social-share-template-container">
      <p className="template-text">{template.text}</p>
      <div className="social-share-image-button-container">
        <img
          src={template.image}
          alt={template.text}
          className="template-image"
        />

        <Button
          variant="contained"
          onClick={handleCopy}
          style={{
            backgroundColor: "var(--color-primary)",
            color: "var(--dark-green)",
          }}
        >
          COPY & PASTE
          <Box sx={{ display: "flex", gap: 0.5, marginLeft: 1 }}>
            <Facebook fontSize="small" />
            <LinkedIn fontSize="small" />
            <Twitter fontSize="small" />
          </Box>
        </Button>
      </div>
    </div>
  );
};

export default SocialShareTemplate;
