import "./App.css";

import React, { useEffect, useState } from "react";

import Button from "../components/button/Button";
import Footer from "../components/footer/Footer";
import Header from "../components/navbar/Header";
import Hoc from "../hoc/Hoc";
import Routes from "./Routes";
import { apiRequest } from "../utils/fetch";
import { hotjar } from "react-hotjar";

function App(Props: any) {
  hotjar.initialize(3687499, 6);

  const window_location = window.location;
  const urlParams = new URLSearchParams(window_location.search);
  const id = urlParams.get("id");
  const contact_id = urlParams.get("contact_id");
  const [path, setPath] = useState("/");
  const pathName = window.location.pathname;

  const handleRequest = (id: any) => {
    apiRequest({
      method: "patch",
      url: `/analytics/email/website/${id}`,
    });
  };

  useEffect(() => {
    if (id) {
      handleRequest(id);
    }
  }, [id]);

  useEffect(() => {
    setPath(pathName);
  }, [pathName]);

  const [toggle, setToggle] = useState<boolean>(false);
  const location = pathName !== "/sign-up";
  const share = pathName !== "/share";
  const email = pathName !== "/email";
  const login = pathName !== "/login";
  const redirect = pathName !== "/redirect";

  const {
    login: {
      user_info: { user },
    },
  } = Props;

  const loginUser =
    (user && user.account_type === "charity") ||
    (user && user.account_type === "admin");

  return (
    <div className="desktop-wrap">
      {toggle && (
        <div className="mobile-menu">
          <div className="close" onClick={() => setToggle(false)}></div>
          <div className="mobile-menu-wrapper">
            <ul>
              <li>
                <a href="/">Extension</a>
              </li>

              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/faq">FAQ</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
              <li>
                <a href="/charity_landing_page">Fundraising</a>
              </li>
              <li>
                <Button
                  text={"Start Fundraising"}
                  link={"/fundraiser-sign-up"}
                  main_color={"var(--dark-green)"}
                  secondary_color={"transparent"}
                />
              </li>
              <li>
                <Button
                  main_color={"var(--dark-green)"}
                  secondary_color={"transparent"}
                />
              </li>
            </ul>
          </div>
        </div>
      )}
      {location && redirect && share && email && !loginUser && (
        <Header setToggle={setToggle} path={path} />
      )}
      <Routes />
      {redirect && share && email && login && <Footer />}
    </div>
  );
}

export default Hoc(App);
