import "../graph/graph.css";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AccordionComponent from "../accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Hoc from "../../hoc/Hoc";
import Typography from "@mui/material/Typography";
import { states } from "../../utils/charity";

interface IEmailBuilder {
  postEmailAnalytics: Function;
  postEmailAnalyticsClear: Function;
  login: any;
  charity: any;
  postContact: Function;
  clearPostContact: Function;
  getEmailAddress: Function;
}

const EmailBuilder: React.FC<IEmailBuilder> = ({
  login,
  postEmailAnalytics,
  postEmailAnalyticsClear,
  charity,
  postContact,
  clearPostContact,
  getEmailAddress,
}) => {
  const {
    user_info: { user },
  } = login;
  const { post_email_admin_data, post_contact, email_address_domain } = charity;

  const contactObject = {
    person_name: "",
    email_address: "",
    email_address_type: "",
  };

  const nonProfitObject = {
    state: "",
    website: "",
    charity_type: "",
    charity_name: "",
    domain: "",
  };

  const [contacts, setContacts] = useState<any>([]);
  const [contact, setContact] = useState(contactObject);

  const [info, setInfo] = useState(nonProfitObject);

  useEffect(() => {
    if (post_contact.length > 0) {
      setContacts([...contacts, post_contact[0]]);
      clearPostContact();
    }
  }, [post_contact]);

  useEffect(() => {
    return () => {
      postEmailAnalyticsClear();
      setInfo(nonProfitObject);
      setContact(contactObject);
      setContacts([]);
    };
  }, []);

  const setInfoHandler = (e: any) => {
    setInfo({ ...info, [e.name]: e.value });
  };

  const setContactHandler = (e: any) => {
    setContact({ ...contact, [e.name]: e.value });
  };

  const postShareLinkHandler = () => {
    postEmailAnalytics(user && user.uuid, info);
    getEmailAddress(info.domain);
  };

  const postContactHandler = () => {
    postContact(post_email_admin_data && post_email_admin_data.id, contact);
    setContact(contactObject);
  };

  const clearHandler = () => {
    postEmailAnalyticsClear();
    setInfo(nonProfitObject);
    setContact(contactObject);
    setContacts([]);
  };

  const disabledHandler =
    info.charity_type.length <= 0 ||
    info.charity_name.length <= 0 ||
    info.state.length <= 0 ||
    info.domain.length <= 0;

  const contactDisableHandler =
    contact.email_address_type.length <= 0 || contact.email_address.length <= 0;

  const personDisableHandler =
    contact.email_address_type.length <= 0 ||
    contact.email_address.length <= 0 ||
    contact.person_name.length <= 0;

  const disabledHandlerAccordion =
    contact &&
    contact.email_address_type &&
    contact.email_address_type === "personal"
      ? personDisableHandler
      : contactDisableHandler;

  const textFieldArray = ["charity_name", "domain"];

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {post_email_admin_data && post_email_admin_data.id ? (
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                textAlign: "center",
                backgroundColor: "#00c7a4",
                justifyContent: "center",
                color: "white",
                fontSize: "24px",
                fontFamily: "Roboto Slab, serif",
                fontWeight: "400",
                padding: "15px 0px",
              }}
            >
              {post_email_admin_data.charity_name}
            </div>
            {contacts.length > 0 &&
              contacts.map((contact: any, idx: number) => (
                <div
                  key={idx}
                  style={{
                    width: "100%",
                    display: "flex",
                    textAlign: "center",
                    backgroundColor: "#ececec",
                    justifyContent: "flex-start",
                    color: "#000",
                    fontSize: "18px",
                    fontFamily: "Roboto Slab, serif",
                    fontWeight: "300",
                    padding: "15px 20px",
                    margin: "10px 0 0 0",
                  }}
                >
                  {contact.email_address}
                </div>
              ))}

            <div
              style={{
                width: "100%",
                display: "flex",
                textAlign: "center",
                backgroundColor: "#0f172a",
                justifyContent: "flex-start",
                color: "#fff",
                fontSize: "18px",
                fontFamily: "Roboto Slab, serif",
                fontWeight: "400",
                padding: "15px 20px",
                margin: "10px 0 0 0",
              }}
            >
              {`Email Pattern ${
                email_address_domain &&
                email_address_domain.data &&
                email_address_domain.data.pattern &&
                email_address_domain.data.pattern.toUpperCase()
              }`}
            </div>
            {email_address_domain &&
              email_address_domain.data &&
              email_address_domain.data.emails.length > 0 &&
              email_address_domain.data.emails.map(
                (email: any, idx: number) => (
                  <div style={{ margin: "20px 0 0 0" }} key={idx}>
                    <AccordionComponent
                      postContact={postContact}
                      post_id={post_email_admin_data.id}
                      email={email}
                      contacts={
                        contacts && contacts[idx] && contacts[idx].email_address
                      }
                    />
                  </div>
                )
              )}
            <div style={{ margin: "20px 0 0 0" }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: "#00c7a4", color: "#fff" }}
                >
                  <Typography>Add Contact</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="EMAIL ADDRESS"
                    variant="outlined"
                    value={contact.email_address}
                    name="email_address"
                    onChange={(e) => setContactHandler(e.target)}
                    style={{ marginTop: "20px" }}
                  />
                  <Box>
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                      <InputLabel id="demo-simple-select-label">
                        EMAIL ADDRESS TYPE
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={contact.email_address_type}
                        name="email_address_type"
                        onChange={(e) => setContactHandler(e.target)}
                      >
                        <MenuItem
                          value={"company"}
                        >{`Company (e.g. info@name.com)`}</MenuItem>
                        <MenuItem value={"personal"}>Personal</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {contact.email_address_type === "personal" && (
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      name="person_name"
                      onChange={(e) => setContactHandler(e.target)}
                      style={{ marginTop: "20px" }}
                    />
                  )}
                  <Button
                    variant="contained"
                    color="inherit"
                    style={{
                      color: disabledHandlerAccordion ? "grey" : "#00c7a4",
                      marginTop: "20px",
                      backgroundColor: disabledHandlerAccordion
                        ? "#d1d1d1"
                        : "#6b21a8",
                    }}
                    onClick={() => postContactHandler()}
                    disabled={disabledHandlerAccordion}
                  >
                    ADD EMAIL
                  </Button>
                </AccordionDetails>
              </Accordion>

              <Button
                variant="contained"
                color="inherit"
                style={{
                  color: !(contacts.length > 0) ? "grey" : "#00c7a4",
                  marginTop: "20px",
                  backgroundColor: !(contacts.length > 0)
                    ? "#d1d1d1"
                    : "#6b21a8",
                }}
                onClick={() => clearHandler()}
                disabled={!(contacts.length > 0)}
              >
                DONE
              </Button>
            </div>
          </div>
        ) : (
          <>
            {textFieldArray.map((value, idx) => {
              return (
                <TextField
                  key={idx}
                  id="outlined-basic"
                  label={value.replace("_", " ").toUpperCase()}
                  variant="outlined"
                  name={value}
                  onChange={(e) => setInfoHandler(e.target)}
                  style={{ marginTop: "20px" }}
                />
              );
            })}
            <Box>
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="demo-simple-select-label">
                  CHARITY TYPE
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={info.charity_type}
                  name="charity_type"
                  onChange={(e) => setInfoHandler(e.target)}
                >
                  <MenuItem value={"domestic"}>Domestic</MenuItem>
                  <MenuItem value={"international"}>International</MenuItem>
                  <MenuItem value={"college_nil"}>College N.I.L</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="demo-simple-select-label">STATE</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={info.state}
                  name="state"
                  onChange={(e) => setInfoHandler(e.target)}
                >
                  {states.map((state) => (
                    <MenuItem key={state} value={state.toLowerCase()}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              color="inherit"
              style={{
                color: disabledHandler ? "grey" : "#00c7a4",
                marginTop: "20px",
                backgroundColor: disabledHandler ? "#d1d1d1" : "#6b21a8",
              }}
              onClick={() => postShareLinkHandler()}
              disabled={disabledHandler}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Hoc(EmailBuilder);
