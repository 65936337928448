import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Hoc from "../../hoc/Hoc";
import { states } from "../../utils/charity";

interface IOrganizationEdit {
  getOrganizationByState: (state: string, charity_type: string) => void;
  charity: { organization_by_state: any[]; post_email_admin_data: any };
  deleteContactById: (id: number) => void;
  postContact: (id: number, contact: any) => void;
}

interface OrganizationCardProps {
  id: number;
  person_name: string;
  email_address: string;
  onDelete: (id: number) => void;
}

const OrganizationCard: React.FC<OrganizationCardProps> = ({
  id,
  person_name,
  email_address,
  onDelete,
}) => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" component="div">
              {person_name}
            </Typography>
            <Typography variant="h6" component="div">
              {email_address}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onDelete(id)}
          >
            Delete
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const BasicTable: React.FC<IOrganizationEdit> = ({
  getOrganizationByState,
  charity,
  deleteContactById,
  postContact,
}) => {
  const { post_email_admin_data } = charity;

  const [info, setInfo] = useState({
    state: "",
    charity_name: "",
    charity_type: "",
  });
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [organizationNames, setOrganizationNames] = useState<string[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<any>([]);
  const contactObject = {
    person_name: "",
    email_address: "",
    email_address_type: "",
  };
  const [contact, setContact] = useState(contactObject);

  useEffect(() => {
    if (info.state || info.charity_type) {
      getOrganizationByState(info.state, info.charity_type);
      setInfo((prevInfo) => ({ ...prevInfo, charity_name: "" }));
      setOrganizations([]);
      setOrganizationNames([]);
      setSelectedOrganization([]);
    }
  }, [info.state, info.charity_type]);

  useEffect(() => {
    if (info.charity_name) {
      const filtered = organizations.filter(
        (organization) => organization.charity_name === info.charity_name
      );
      setSelectedOrganization(filtered);
    }
  }, [info.charity_name]);

  const handleInputChange: any = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    if (name) {
      setInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
    }
  };

  const setContactHandler: any = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    if (name) {
      setContact((prevContact) => ({ ...prevContact, [name]: value }));
    }
  };

  const postContactHandler = () => {
    const id =
      selectedOrganization &&
      selectedOrganization[0] &&
      selectedOrganization[0].contact_id;

    if (id) {
      postContact(id, contact);
      setContact(contactObject);
    }
  };

  const handleDelete = (id: number) => {
    const remainingContacts = selectedOrganization.filter(
      (org: any) => org.id !== id
    );
    console.log("remainingContacts = ", remainingContacts);
    deleteContactById(id);
    setSelectedOrganization(remainingContacts);
  };

  useEffect(() => {
    const organizationNames = charity.organization_by_state
      .map((organization) => organization.charity_name)
      .filter((name, index, self) => self.indexOf(name) === index)
      .sort((a, b) => a.localeCompare(b));

    setOrganizations(charity.organization_by_state);
    setOrganizationNames(organizationNames);
  }, [charity.organization_by_state]);

  const contactDisableHandler =
    !contact.email_address_type || !contact.email_address;

  const personDisableHandler =
    !contact.email_address_type ||
    !contact.email_address ||
    !contact.person_name;

  const disabledHandlerAccordion =
    contact.email_address_type === "personal"
      ? personDisableHandler
      : contactDisableHandler;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FormControl fullWidth style={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-label">CHARITY TYPE</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="charity_type-select"
            value={info.charity_type}
            name="charity_type"
            onChange={handleInputChange}
          >
            <MenuItem value={"domestic"}>Domestic</MenuItem>
            <MenuItem value={"international"}>International</MenuItem>
            <MenuItem value={"college_nil"}>College N.I.L</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="state-select-label">State</InputLabel>
          <Select
            labelId="state-select-label"
            id="state-select"
            value={info.state}
            name="state"
            onChange={handleInputChange}
          >
            {states.map((state) => (
              <MenuItem key={state} value={state.toLowerCase()}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {organizations.length > 0 && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="organization-select-label">
              Organization Names
            </InputLabel>
            <Select
              labelId="organization-select-label"
              id="organization-select"
              value={info.charity_name}
              name="charity_name"
              onChange={handleInputChange}
            >
              {organizationNames.map((organization, idx) => (
                <MenuItem key={idx} value={organization}>
                  {organization}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Box sx={{ mt: 2 }}>
          {selectedOrganization.map((org: any, index: number) => (
            <OrganizationCard
              key={index}
              id={org.id}
              person_name={org.person_name}
              email_address={org.email_address}
              onDelete={handleDelete}
            />
          ))}
        </Box>

        {selectedOrganization.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: "#00c7a4", color: "#fff" }}
              >
                <Typography>Add Contact</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  fullWidth
                  label="EMAIL ADDRESS"
                  variant="outlined"
                  value={contact.email_address}
                  name="email_address"
                  onChange={setContactHandler}
                  sx={{ mt: 2 }}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="email-type-select-label">
                    EMAIL ADDRESS TYPE
                  </InputLabel>
                  <Select
                    labelId="email-type-select-label"
                    id="email-type-select"
                    value={contact.email_address_type}
                    name="email_address_type"
                    onChange={setContactHandler}
                  >
                    <MenuItem
                      value={"company"}
                    >{`Company (e.g. info@name.com)`}</MenuItem>
                    <MenuItem value={"personal"}>Personal</MenuItem>
                  </Select>
                </FormControl>

                {contact.email_address_type === "personal" && (
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={contact.person_name}
                    name="person_name"
                    onChange={setContactHandler}
                    sx={{ mt: 2 }}
                  />
                )}
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{
                    color: disabledHandlerAccordion ? "grey" : "#00c7a4",
                    mt: 2,
                    backgroundColor: disabledHandlerAccordion
                      ? "#d1d1d1"
                      : "#6b21a8",
                  }}
                  onClick={postContactHandler}
                  disabled={disabledHandlerAccordion}
                >
                  ADD EMAIL
                </Button>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Hoc(BasicTable);
