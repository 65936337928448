import * as actionTypes from "../types/organizationCampaign";

const initialState = {
  organization_campaigns: [],
  organization_campaigns_error: "",
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case actionTypes.GET_ALL_ORGANIZATION_CAMPAIGNS_FULFILLED:
      return {
        ...state,
        organization_campaigns: action.payload,
      };
    case actionTypes.GET_ALL_ORGANIZATION_CAMPAIGNS_ERROR:
      return {
        ...state,
        organization_campaigns_error: true,
      };

    default:
      return state;
  }
};
