import * as actionTypes from "../types/organizationCampaign";

import { Dispatch } from "redux";
import { restCall } from "../../utils/fetch";

export const getAllOrganizationCampaigns = (id: string) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/share/${id}`,
      method: "get",
      successType: actionTypes.GET_ALL_ORGANIZATION_CAMPAIGNS_FULFILLED,
      errorType: actionTypes.GET_ALL_ORGANIZATION_CAMPAIGNS_ERROR,
      dispatch,
    });
  };
};
